// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
//@import '~bootstrap/scss/bootstrap';
//@import '~admin-lte/dist/css/adminlte.css';

// Font Awesome
/*
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
*/
// sweet alert
@import 'sweetalert2/src/sweetalert2.scss';
// import 'sweetalert2/src/sweetalert2.scss'

/*
.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}
*/

.blue {color: $blue;}
.indigo {color: $indigo;}
.purple {color: $purple;}
.pink {color: $pink;}
.red {color: $red;}
.orange {color: $orange;}
.yellow {color: $yellow;}
.green {color: $green;}
.teal {color: $teal;}
.cyan {color: $cyan;}


// .content-header{
//   padding: 10px 0.5rem !important;
// }

@import '~vuetify/dist/vuetify.min.css';
@import '~@mdi/font/css/materialdesignicons.min.css';

.main_content{
  width: calc( 100% - 16rem ) ;
}
.right_side_bar {
width: 16rem
}

.v-table td {
  background: #fff;
}
.v-table tr:nth-child(odd) td {
/*    background-color: #F5F5F5; */
  background-color: #F8F8F8;
  
}
.v-table tr:hover td {
  /*background: #f0f8ff;*/
  background: #DEEAFF;
  transition: background-color .25s;
  
}


section {
  margin-top: 12px;
}
